<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>提现管理</el-breadcrumb-item>
                    <el-breadcrumb-item>提现风控</el-breadcrumb-item>
                    <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-form ref="form" :model="form" label-width="180px">
            <div class="admin_main_block admin_m15">
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item
                                label="会员ID"
                                prop="userId"
                                :rules="[
                                    {
                                      required: true,
                                      message: '会员ID不能为空',
                                      trigger: 'blur',
                                    }
                                  ]"
                        >
                            <el-input
                                    placeholder=""
                                    v-model="form.userId"
                                    disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item
                                label="手机号码（会员账号）"
                                prop="accountNo"
                                :rules="[
                                    {
                                      required: true,
                                      message: '请输入手机号码（会员账号）',
                                      trigger: 'blur',
                                    },
                                    {
                                      min: 11,
                                      max: 11,
                                      message: '长度在 11 个字符',
                                      trigger: 'blur',
                                    },
                                  ]"
                        >
                            <el-input
                                    @blur="getUserinfo"
                                    placeholder=""
                                    v-model="form.accountNo"
                                    :disabled="disabled"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item
                                label="收款账号（银行卡号）"
                                prop="userBankId"
                                :rules="[
                                    { required: true, message: '收款账号（银行卡号）', trigger: 'blur' },
                                  ]"
                        >
                            <el-select
                                    v-model="form.userBankId"
                                    placeholder="请选择"
                                    clearable
                            >
                                <el-option v-for="(item,index) in userBankList" :key="index" :label="item.bankName + '' + item.bankAccount" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item
                                label="日笔数限制"
                                prop="dayCountLimit"
                                :rules="[
                                    { required: true, message: '请输入日笔数限制', trigger: 'blur' },
                                  ]"
                        >
                            <el-input-number
                                    v-model="form.dayCountLimit"
                                    controls-position="right"
                                    placeholder="请输入日笔数限制:1-100"
                                    :min="1"
                                    :max="100"
                                    class="price"
                                    clearable
                            ></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item
                                label="日汇总限制"
                                prop="daySumLimit"
                                :rules="[
                                    { required: true, message: '请输入日汇总限制', trigger: 'blur' },
                                  ]"
                        >
                            <el-input-number
                                    v-model="form.daySumLimit"
                                    controls-position="right"
                                    placeholder="请输入日汇总限制:1-100000"
                                    :min="1"
                                    :precision="2" :step="0.1"
                                    :max="100000"
                                    class="price"
                                    clearable
                            ></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item
                                label="单笔限额"
                                prop="singleMaxLimit"
                                :rules="[
                                    { required: true, message: '请输入单笔限额', trigger: 'blur' },
                                  ]"
                        >
                            <el-input-number
                                    v-model="form.singleMaxLimit"
                                    controls-position="right"
                                    placeholder="请输入单笔限额:1-50000"
                                    :min="1"
                                    :precision="2" :step="0.1" :max="50000"
                                    class="price"
                                    clearable
                            ></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="admin_main_block admin_m15">
                <el-row :gutter="40">
                    <el-col :span="24">
                        <el-form-item label="" >
                            <el-button icon="el-icon-back" @click="back">
                                返回
                            </el-button>
                            <el-button icon="el-icon-check" type="primary"
                                    @click="$clickBtn(success('form'))">
                                提交
                            </el-button>
                        </el-form-item>

                    </el-col>
                </el-row>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                disabled: false,
                span: 8,
                userBankList:'',
                title:'添加',
                id:'',
                form: {
                    id:'',
                    userId: '',//用户id
                    accountNo: '',//手机号
                    userBankId: '',//收款账号（银行卡号）
                    operator: '',//操作人
                    dayCountLimit: 5,//日笔数限制
                    daySumLimit: 1000,//日汇总限制
                    singleMaxLimit: 500,//单笔限额
                },
            };
        },
        methods: {
            success(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$confirm("是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        })
                            .then(() => {
                                //修改||新增根据id进行判断
                                let url = this.$api.profitCashLimitAdd;
                                if(this.form.id){
                                    url = this.$api.profitCashLimitEdit;
                                }
                                this.post(url, this.form);
                            })
                            .catch(() => {
                                this.$message({
                                    type: "info",
                                    message: "已取消",
                                });
                            });
                    } else {
                        this.$message({
                            type: "warning",
                            message: "请填写完整信息！",
                        });
                        return false;
                    }
                });
            },
            back() {
                this.$router.go(-1);
            },
            post(url, data) {
                this.$post(url, data).then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            type: "success",
                            message: "成功",
                        });
                        this.back();
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                        });
                    }
                });
            },
            getUserinfo() {
                if (this.$isEmpty(this.form.accountNo)) {
                    return false;
                }
                this.$get(this.$api.getUserInfoByPhone + this.form.accountNo, {}).then(
                    (res) => {
                        if (res.code === 1) {
                            this.form.userId = res.data.userId;
                            this.userBankList = res.data.userBankList;
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg,
                            });
                        }
                    }
                );
            },
            //获取编辑详情内容
            getDetail(){
                this.$get(this.$api.getProfitById + this.form.id, {}).then(
                    (res) => {
                        if (res.code === 1) {
                            this.form = res.data;
                            this.getUserinfo();
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg,
                            });
                        }
                    }
                );
            },
            //初始化
            initData(){
                const id = this.$route.query.id;

                let user_info = localStorage.getItem("user_info");
                let user = JSON.parse(user_info);
                this.form.operator = user.nickname || user.id;

                if(id){
                    this.form.id = id;
                    this.title = '编辑';
                    this.getDetail();
                }
            },
        },
        created() {
            this.initData();
        },
    };
</script>
<style lang="scss" scoped>
    .el-card {
        text-align: right;
        margin-top: 30px;
    }

    .hight {
        /*height: 60px;*/
    }

    .el-select {
        width: 100%;
    }

    .el-input {
        width: 100%;
    }

    .el-divider--horizontal {
        margin: 12px 0 12px 0;
    }

    .price {
        width: 200px;
    }

    .el-input-number--small {
        width: 200px;
    }
</style>
