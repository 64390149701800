var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qingwu"},[_c('div',{staticClass:"admin_main_block"},[_c('div',{staticClass:"admin_breadcrumb"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/Admin/index' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("提现管理")]),_c('el-breadcrumb-item',[_vm._v("提现风控")]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.title))])],1)],1)]),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"180px"}},[_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-row',[_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"会员ID","prop":"userId","rules":[
                                {
                                  required: true,
                                  message: '会员ID不能为空',
                                  trigger: 'blur',
                                }
                              ]}},[_c('el-input',{attrs:{"placeholder":"","disabled":""},model:{value:(_vm.form.userId),callback:function ($$v) {_vm.$set(_vm.form, "userId", $$v)},expression:"form.userId"}})],1)],1)],1),_c('el-row',[_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"手机号码（会员账号）","prop":"accountNo","rules":[
                                {
                                  required: true,
                                  message: '请输入手机号码（会员账号）',
                                  trigger: 'blur',
                                },
                                {
                                  min: 11,
                                  max: 11,
                                  message: '长度在 11 个字符',
                                  trigger: 'blur',
                                } ]}},[_c('el-input',{attrs:{"placeholder":"","disabled":_vm.disabled},on:{"blur":_vm.getUserinfo},model:{value:(_vm.form.accountNo),callback:function ($$v) {_vm.$set(_vm.form, "accountNo", $$v)},expression:"form.accountNo"}})],1)],1)],1),_c('el-row',[_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"收款账号（银行卡号）","prop":"userBankId","rules":[
                                { required: true, message: '收款账号（银行卡号）', trigger: 'blur' } ]}},[_c('el-select',{attrs:{"placeholder":"请选择","clearable":""},model:{value:(_vm.form.userBankId),callback:function ($$v) {_vm.$set(_vm.form, "userBankId", $$v)},expression:"form.userBankId"}},_vm._l((_vm.userBankList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.bankName + '' + item.bankAccount,"value":item.id}})}),1)],1)],1)],1),_c('el-row',[_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"日笔数限制","prop":"dayCountLimit","rules":[
                                { required: true, message: '请输入日笔数限制', trigger: 'blur' } ]}},[_c('el-input-number',{staticClass:"price",attrs:{"controls-position":"right","placeholder":"请输入日笔数限制:1-100","min":1,"max":100,"clearable":""},model:{value:(_vm.form.dayCountLimit),callback:function ($$v) {_vm.$set(_vm.form, "dayCountLimit", $$v)},expression:"form.dayCountLimit"}})],1)],1)],1),_c('el-row',[_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"日汇总限制","prop":"daySumLimit","rules":[
                                { required: true, message: '请输入日汇总限制', trigger: 'blur' } ]}},[_c('el-input-number',{staticClass:"price",attrs:{"controls-position":"right","placeholder":"请输入日汇总限制:1-100000","min":1,"precision":2,"step":0.1,"max":100000,"clearable":""},model:{value:(_vm.form.daySumLimit),callback:function ($$v) {_vm.$set(_vm.form, "daySumLimit", $$v)},expression:"form.daySumLimit"}})],1)],1)],1),_c('el-row',[_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"单笔限额","prop":"singleMaxLimit","rules":[
                                { required: true, message: '请输入单笔限额', trigger: 'blur' } ]}},[_c('el-input-number',{staticClass:"price",attrs:{"controls-position":"right","placeholder":"请输入单笔限额:1-50000","min":1,"precision":2,"step":0.1,"max":50000,"clearable":""},model:{value:(_vm.form.singleMaxLimit),callback:function ($$v) {_vm.$set(_vm.form, "singleMaxLimit", $$v)},expression:"form.singleMaxLimit"}})],1)],1)],1)],1),_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-row',{attrs:{"gutter":40}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":""}},[_c('el-button',{attrs:{"icon":"el-icon-back"},on:{"click":_vm.back}},[_vm._v(" 返回 ")]),_c('el-button',{attrs:{"icon":"el-icon-check","type":"primary"},on:{"click":function($event){_vm.$clickBtn(_vm.success('form'))}}},[_vm._v(" 提交 ")])],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }